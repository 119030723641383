export enum LocaleCode {
  SL = 'sl',
  EN = 'en',
  HR = 'hr'
}

export interface Locale {
  code: LocaleCode
  domain?: string
  file: string
  iso: string
  name: string
}
